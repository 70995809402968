.HealthIndex {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 0 !important;
}

.HealthIndex span {
  color: #AEB4CB;
  font-size: 1.1rem;
  font-weight: 500;
}