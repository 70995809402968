.grid-container {
  margin: 0 3rem;
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-gap: 4.5em;
  align-content: center;

}

@media (max-width: 1280px) {
  .grid-container {
    grid-gap: 3em;
  }
}

@media (max-width: 1024px) {
  .grid-container {
    grid-gap: 2em;
  }
}

@media (max-width: 924px) {
  .grid-container {
    margin: 2rem 1rem;
    grid-template-columns: 1fr;
    grid-template-areas: ".";
  }
}
