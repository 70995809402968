.blue {
  color: #80ff86;
}
.green {
  color: #80ff86;
}
.yellow {
  color: #ffca66;
}
.orange {
  color: #ffca66;
}
.red {
  color: #ff7a8a;
}

h1 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: 500;
  /* text-transform: uppercase;
  letter-spacing: 0.15rem; */
}

.Device header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 50px;
  margin-top: 0.5rem;
  margin-bottom: 1em;
  justify-content: space-between;
}

.Device header h1 {
  vertical-align: middle;
  line-height: 40px;
}

.Device .CircularProgressbar {
  width: 40px;
}

.Device > * + * {
  margin-bottom: 1.75em;
}
