.ErrorBar {
  height: 2rem;
  line-height: 2rem;
  vertical-align: middle;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 122, 138, 0.15);
  color: #ff7a8a;
  overflow: hidden;
  padding: 0 1rem;
}

.ErrorComponent .Error {
  max-width: 90%;
}
